import { inject, Injectable } from "@angular/core";
import { AugmentedReferenceData, OAUTH_HANDLER_KEY } from "common";
import { Language, PilotwebReferenceDataEndpoint } from "apina-frontend";

@Injectable({providedIn: "root"})
export class PreloadedDataService {

    private _referenceData?: AugmentedReferenceData;
    private readonly referenceDataEndpoint = inject(PilotwebReferenceDataEndpoint);

    get referenceData(): AugmentedReferenceData {
        if (this._referenceData === undefined)
            throw "Reference data not initialized";

        return this._referenceData;
    }

    async initialize(): Promise<void> {
        if (this._referenceData === undefined)
            this._referenceData = new AugmentedReferenceData(await this.referenceDataEndpoint.loadReferenceData(Language.FINNISH));
    }
}

export async function initializePreloadedData(): Promise<void> {
    const preloadedDataService = inject(PreloadedDataService);
    const oauthHandler = inject(OAUTH_HANDLER_KEY);

    if (await oauthHandler.isLoggedIn()) {
        await preloadedDataService.initialize();
    }
}
